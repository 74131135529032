import React, { Component } from "react"

import Layout from "../components/layout"
import MetaTags from '../components/MetaTags.js';

export class NotFoundPage extends Component {
  componentDidMount() {
    window.location = "/"
  }
  render() {
    return (
      <Layout>
        <MetaTags
          title={"404: Not found"}
          description={"404: Not found"}
          thumbnail={"https://s3.eu-central-1.amazonaws.com/fragrancebutler.me/share_image/CTA_share/share_image_sdp.png"}
          // url={url_post}
          pathname={""}
          keywords={[]}
        />
        <h1 style={{ width: "100%", borderBottom: ".2px solid #ddd", paddingBottom: "5px", fontFamily: "FuturaLight", fontSize: "16px", letterSpacing: "2.2px", textTransform: 'uppercase', color: "#3a3a3a" }}>
          Vous semblez être au mauvais endroit
        </h1>
        <p>
          La page que vous cherchez n'existe pas, désolé !
        </p>
      </Layout>
    )
  }
}

export default NotFoundPage
